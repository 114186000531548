import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer"

import svg1 from "../images/culture-illustrations/1.svg"
import svg2 from "../images/culture-illustrations/2.svg"
import svg3 from "../images/culture-illustrations/3.svg"
import svg4 from "../images/culture-illustrations/4.svg"

import CultureStyles from "./culture.module.css"
const Culture = () => {
  const [trigger1, setTrigger1] = useState(false)
  const [trigger2, setTrigger2] = useState(false)
  const [trigger3, setTrigger3] = useState(false)
  const [trigger4, setTrigger4] = useState(false)
  /*
  useEffect(() => {
    window.addEventListener("scroll", svgTrigger)
    svgTrigger()
    return () => {
      window.removeEventListener("scroll", svgTrigger)
    }
  }, [])

  const svgTrigger = () => {
    var scrollY = window.scrollY || window.pageYOffset

    //console.log(scrollY)

    var preContent = document.getElementById("preContent")
    var preContentHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger1Elem = document.getElementById("trigger1")
    var trigger1ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger2Elem = document.getElementById("trigger2")
    var trigger2ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger3Elem = document.getElementById("trigger3")
    var trigger3ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger4Elem = document.getElementById("trigger4")
    var trigger4ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    //TRIGGER 1 POINT
    const trigger1point = preContentHeight - trigger1ElemHeight / 2
    if (scrollY > trigger1point) {
      setTrigger1(true)
    } else {
      setTrigger1(false)
    }

    //TRIGGER 2 POINT
    const trigger2point =
      preContentHeight + trigger1ElemHeight - trigger2ElemHeight / 2
    if (scrollY > trigger2point) {
      setTrigger2(true)
    } else {
      setTrigger2(false)
    }

    //TRIGGER 3 POINT
    const trigger3point =
      preContentHeight +
      trigger1ElemHeight +
      trigger2ElemHeight -
      trigger3ElemHeight / 2
    if (scrollY > trigger3point) {
      setTrigger3(true)
    } else {
      setTrigger3(false)
    }

    //TRIGGER 4 POINT
    const trigger4point =
      preContentHeight +
      trigger1ElemHeight +
      trigger2ElemHeight +
      trigger3ElemHeight -
      trigger4ElemHeight / 2
    if (scrollY > trigger4point) {
      setTrigger4(true)
    } else {
      setTrigger4(false)
    }
  }
  */

  return (
    <Layout>
      <SEO title="İyilik Sağlık Söyleşileri" />
      <h1 className={"hidden"}>İyilik Sağlık Söyleşileri</h1>
      <div className="row mt-90" id="preContent">

          <iframe id="videoIframe" src='https://embed.justcanli.com/iyilik-saglik-soylesileri/stream' frameborder='0' allow='autoplay; fullscreen' allowfullscreen mozallowfullscreen webkitallowfullscreen></iframe>
      </div>

      <Footer />
    </Layout>
  )
}

export default Culture
