import React from "react"
import { Link } from "gatsby"
import FooterStyles from "./footer.module.css"
import LOGO from "../../images/manastir-logo.svg"

function Footer() {
  return (
    <>
      <footer className={FooterStyles.footer}>
        <div className={`${FooterStyles.wrapper} ${FooterStyles.address}`}>
          <div>Asmalı Mescit Mah.</div>
          <div>General Yazgan Sk. No:14/A</div>
          <div>34430 Beyoğlu/İstanbul</div>
          <div>+90 (212) 274 74 09</div>
          <div>merhaba@manastir.net</div>
        </div>
        <div className={FooterStyles.socials}>
          <a
            href="https://www.instagram.com/manastiragency/"
            target="_blank"
            rel="noopener noreferrer"
            className={FooterStyles.link}
          >
            instagram
          </a>
          {"-"}
          <a
            href="https://twitter.com/manastiragency"
            target="_blank"
            rel="noopener noreferrer"
            className={FooterStyles.link}
          >
            twitter
          </a>
          {"-"}
          <a
            href="https://www.facebook.com/manastiragency"
            target="_blank"
            rel="noopener noreferrer"
            className={FooterStyles.link}
          >
            facebook
          </a>
          {"-"}
          <a
            href="https://www.linkedin.com/company/manastiragency/"
            target="_blank"
            rel="noopener noreferrer"
            className={FooterStyles.link}
          >
            linkedin
          </a>
        </div>
        <div className={FooterStyles.logo}>
          <Link to="/">
            <img src={LOGO} alt="manastir" />
          </Link>
        </div>
      </footer>
    </>
  )
}
export default Footer
